import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
	selector: "pcode-root",
	template: "<router-outlet></router-outlet>"
})

export class AppComponent implements OnInit
{
	// constructor
	constructor
		(
			private titleService: Title,
			private activatedRoute: ActivatedRoute,
			private router: Router,
			private translateService: TranslateService,
			@Inject(DOCUMENT) private document: Document
		)
	{
		// this language will be used as a fallback when a translation isn't found in the current language
		translateService.setDefaultLang('it');

		// get user lang from localstorage
		const userLanguage = localStorage.getItem('user_lang') || 'it';

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translateService.use(userLanguage);
	}

	public setTitle(newTitle: string)
	{
		this.titleService.setTitle(newTitle);
	}

	//called after the constructor and called  after the first ngOnChanges()
	//ngOnInit() is better place to "start" - it's where/when components' bindings are resolved.
	ngOnInit()
	{
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route: ActivatedRoute) =>
				{
					while (route.firstChild) route = route.firstChild;
					return route;
				}),
				filter((route: ActivatedRoute) => route.outlet === 'primary'),
				mergeMap((route: ActivatedRoute) => route.data)
			)
			.subscribe((event) =>
			{
				this.translateService.get(event['title']).subscribe((translated: string) =>
				{
					/*if (this.router.url.startsWith('/web'))*/
					this.titleService.setTitle(translated + ' - Digifran')
				});
			});

		this.router.events.subscribe((evt) =>
		{
			if (!(evt instanceof NavigationEnd))
			{
				return;
			}
			this.document.body.scrollTop = 0;
		});
	}
}
