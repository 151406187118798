import { Injectable } from '@angular/core';

@Injectable()
export class AuthService
{
	// register access token to storage
	static setAccessToken(token: string)
	{
		localStorage.setItem("access_token", token);
	}

	// get access token from storage
	static getAccessToken(): string
	{
		const accessToken = localStorage.getItem("access_token");
		return accessToken;
	}

	// clear access token
	static removeAccessToken()
	{
		localStorage.removeItem("access_token");
	}

	// register refresh token to storage
	static setRefreshToken(token: string)
	{
		localStorage.setItem("refresh_token", token);
	}

	// get refresh token
	static getRefreshToken()
	{
		return localStorage.getItem('refresh_token');
	}

	// clear refresh token
	static removeRefresToken()
	{
		localStorage.removeItem("refresh_token");
	}

	// set azure ad last access
	static setAzureAdLastAccess(user: string)
	{
		localStorage.setItem("azuread_lastaccess", user);
	}

	// get azure ad last access
	static getAzureAdLastAccess()
	{
		return localStorage.getItem("azuread_lastaccess");
	}

	// remove azure ad last access
	static removeAzureAdLastAccess()
	{
		localStorage.removeItem("azuread_lastaccess");
	}
}