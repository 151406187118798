import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';

const routes: Routes =
	[
		// login
		{
			path: "login",
			loadChildren: () => import('./shared/pages/login/login/login.module').then(m => m.LoginModule),
			data: { title: 'Digifran' },
		},
		{
			path: "login/resetpassword",
			loadChildren: () => import('./shared/pages/login/resetpassword/resetpassword.module').then(m => m.ResetPasswordModule),
			data: { title: 'Digifran' }
		},

		// webapp
		{
			path: "",
			loadChildren: () => import('./webapp/layout/layout.module').then(m => m.LayoutModule)
		},

		// not found
		{
			path: "notfound",
			loadChildren: () => import('./shared/pages/page-not-found/pagenotfound.module').then(m => m.PageNotFoundModule),
			data: { title: 'Pagina non trovata' }
		},

		// forbidden
		{
			path: "forbidden",
			loadChildren: () => import('./shared/pages/forbidden/forbidden.module').then(m => m.ForbiddenModule),
			data: { title: 'Accesso negato' }
		},

		// 404 page not found
		//----------------------------------------------------------
		{
			path: "**",
			redirectTo: "notfound"
		}
	];

const routerOptions: ExtraOptions =
{
	useHash: false,
	anchorScrolling: 'enabled',
	scrollPositionRestoration: 'enabled',
	preloadingStrategy: QuicklinkStrategy,
	relativeLinkResolution: 'legacy'
};

@NgModule({
	imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(routes, routerOptions)
		],
	exports: [RouterModule]
})
export class AppRoutingModule { }
