
export class PCodeConfig
{
	static azureAd: PCodeConfigAzureAd =
		{
			tenantId: '',
			clientId: ''
		}

	static vapid: PCodeConfigVapid =
		{
			publicKey: 'BHizkSp85qDkNe3OA_maTi-W3tO5aEPkwaCGDdMrKFyUFNUWM5cBo3ccF3aaQxo_PMheg1b71wixiqWe3YqYlkg'
		}
}

interface PCodeConfigAzureAd
{
	tenantId: string;
	clientId: string;
}
interface PCodeConfigVapid
{
	publicKey: string;
}