import { Injectable } from '@angular/core';

@Injectable()
export class AjaxLoaderService
{
    private selector = 'ajax-loader';
    private element: HTMLElement;

    constructor()
    {
        this.element = document.getElementById(this.selector);
    }

    public show(): void
    {
        if (this.element)
            this.element.style['display'] = 'block';
    }

    public hide(delay = 300): void
    {
        if (this.element)
        {
            setTimeout(() =>
            {
                this.element.style['display'] = 'none';
            }, delay);
        }
    }
}
