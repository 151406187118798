import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../shared/services/authentication/auth.service';
import { InAppNotificationsCount, PushSubscription } from './notifications.models';

@Injectable()
export class WebAppNotificationsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// subscribe
	public subscribe(Subscription: PushSubscription)
	{
		return this.http.post(this.baseUrl + 'api/shared/notifications/subscribe', Subscription);
	}

	// unsubscribe
	public unsubscribe(Subscription: PushSubscription)
	{
		return this.http.post(this.baseUrl + 'api/shared/notifications/unsubscribe', Subscription);
	}

	// get subscription status
	public getSubscriptionStatus(Subscription: PushSubscription)
	{
		return this.http.post(this.baseUrl + 'api/shared/notifications/getSubscriptionStatus', Subscription);
	}

	// get push notifications devices
	public getPushNotificationsDevices()
	{
		return this.http.get(this.baseUrl + 'api/shared/notifications/getPushNotificationsDevices');
	}

	// send push message
	public sendPushMessage(PushMessage: any)
	{
		return this.http.post(this.baseUrl + 'api/shared/notifications/sendPushMessage', PushMessage);
	}

	// get user notifications
	public getUserNotifications(In: boolean, Out: boolean)
	{
		return this.http.get(this.baseUrl + 'api/shared/notifications/getUserNotifications?In=' + In + "&Out=" + Out);
	}

	// get notification
	public getNotification(id: number)
	{
		return this.http.get(this.baseUrl + 'api/shared/notifications/getNotification?Id=' + id);
	}

	// set notification as read
	public setNotificationAsRead(id?: number)
	{
		return this.http.post(this.baseUrl + 'api/shared/notifications/setNotificationAsRead', { id: id });
	}

	// get user notifications count
	public getUserNotificationsCount(): Observable<InAppNotificationsCount>
	{
		return this.http.get<InAppNotificationsCount>(`${this.baseUrl}api/shared/notifications/userNotificationsCount`,
			{
				headers: { Authorization: `Bearer ${AuthService.getAccessToken()}` }
			});
	}
}
